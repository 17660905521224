/* eslint-disable react-hooks/exhaustive-deps */
import "./FeedbackReportPages.scss";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardHeader,
  Select,
} from "../../../../_metronic/_partials/controls";
import { actions as feedbackSettingActions } from "../redux/feedbackSettingRedux";
import FeedbackReportContainer from "../components/report/FeedbackReport";

const FeedbackReportSchema = () =>
  Yup.object().shape({
    feedbackPeriod: Yup.string("Feedback Period must be a string").required(
      "Feedback Period  is required"
    ),
  });

const FeedbackReport = () => {
  const { feedbackReportLoading, feedbackReportFormData } = useSelector(
    (state) => state.feedbackSettings
  );

  const feedbackPeriods = feedbackReportFormData?.["feedback-periods"];

  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  useEffect(() => {
    dispatch(feedbackSettingActions.setFeedbackReport({}));
    dispatch(
      feedbackSettingActions.getFeedbackReportFormData(cookies.accessToken)
    );
  }, [dispatch, cookies.accessToken]);

  const handleReportSubmit = (values) => {
    dispatch(
      feedbackSettingActions.getFeedbackReport(values, cookies.accessToken)
    );
  };

  return (
    <Card>
      <CardHeader title={"Feedback Report"}></CardHeader>
      <CardBody>
        <Formik
          enableReinitialize={true}
          validationSchema={FeedbackReportSchema}
          initialValues={{
            feedbackPeriod: "",
            reportType: "",
            clientType: "all",
          }}
          onSubmit={(values) => {
            handleReportSubmit(values);
          }}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            setFieldValue,
            handleBlur,
          }) => {
            return (
              <>
                {feedbackReportLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right" onSubmit={handleSubmit}>
                  <div className="form-group row">
                    <div className="col-xs-12 col-md-5">
                      <Select
                        id="feedbackPeriod"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("clientType", "all");
                          dispatch(
                            feedbackSettingActions.setFeedbackReport({})
                          );
                        }}
                        value={values?.feedbackPeriod}
                        name="feedbackPeriod"
                        label="Feedback Period"
                        disabled={feedbackReportLoading}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Feedback Period</option>
                        {feedbackPeriods?.map((period) => (
                          <option key={period} value={period}>
                            {period}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div className="col-xs-12 col-md-3">
                      <Select
                        id="clientType"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(
                            feedbackSettingActions.setFeedbackReport({})
                          );
                        }}
                        value={values?.clientType}
                        name="clientType"
                        label="Client Type"
                        disabled={feedbackReportLoading}
                        onBlur={handleBlur}
                      >
                        <option value="all">All</option>
                        <option value="standard">Standard</option>
                        <option value="enterprise">Enterprise</option>
                      </Select>
                    </div>
                    <div className="col-xs-2 col-lg-4 ">
                      <label htmlFor="chart">{""} &nbsp;</label>
                      <br />
                      <div className="feedback-report-buttons">
                        <button
                          type="button"
                          name="chart"
                          id="chart"
                          className="btn btn-primary btn-elevate"
                          disabled={
                            !values?.feedbackPeriod || feedbackReportLoading
                          }
                          onClick={() => {
                            setFieldValue("reportType", "chart");
                            handleSubmit();
                          }}
                        >
                          Show Report
                        </button>
                        <button
                          type="button"
                          name="export"
                          id="export"
                          className="btn btn-secondary btn-elevate"
                          disabled={
                            !values?.feedbackPeriod || feedbackReportLoading
                          }
                          onClick={() => {
                            setFieldValue("reportType", "export");
                            handleSubmit();
                          }}
                        >
                          Export Report
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="separator separator-dashed my-5" />
                  </div>
                  <FeedbackReportContainer values={values} />
                </Form>
              </>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default memo(FeedbackReport);
