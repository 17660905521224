/* eslint-disable react-hooks/exhaustive-deps */
import "./FeedbackReportPages.scss";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Svg from "react-inlinesvg";

import {
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";

import {
  Input,
  Card,
  CardBody,
  CardHeader,
  Select,
} from "../../../../_metronic/_partials/controls";
import { actions as feedbackSettingActions } from "../redux/feedbackSettingRedux";
import Table from "../../../components/Table";
import FeedbackQuestionDialog from "../components/FeedbackQuestionDialog";

const FeedbackSchema = () =>
  Yup.object().shape({
    title: Yup.string("Title must be a string").required("Title is required"),
    completion_message: Yup.string(
      "Completion message must be a string"
    ).required("Completion message is required"),
    schedule_type_org: Yup.string("Schedule type must be a string").required(
      "Schedule type is required"
    ),
    expiry_in_days: Yup.number("Expiry in days must be a number").required(
      "Expiry in days is required"
    ),
    status_org: Yup.string("Status must be a string").required(
      "Status is required"
    ),
  });

const FeedbackSettings = () => {
  const {
    feedbackLoading,
    feedback,
    feedbackQuestionLoading,
    feedbackQuestions,
    totalFeedbackQuestionRecords,
    maxFeedbackQuestionPerPage,
    currentQuestionPage,
    feedbackQuestionSortField,
    feedbackQuestionSortOrder,
  } = useSelector((state) => state.feedbackSettings);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalFeedbackQuestionRecords || 0,
    sizePerPage: maxFeedbackQuestionPerPage,
    page: currentQuestionPage + 1,
  };

  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  useEffect(() => {
    dispatch(feedbackSettingActions.getFeedback(cookies.accessToken));
  }, [dispatch, cookies.accessToken]);

  useEffect(() => {
    if (feedback?.id) {
      dispatch(
        feedbackSettingActions.getFeedbackQuestion(
          feedback?.id,
          feedbackQuestionSortField,
          feedbackQuestionSortOrder,
          currentQuestionPage,
          maxFeedbackQuestionPerPage,
          cookies.accessToken
        )
      );
    }
  }, [feedback]);

  const saveFeedbackData = (values) => {
    dispatch(
      feedbackSettingActions.updateFeedbackData(values, cookies.accessToken)
    );
  };

  const deleteQuestion = (feedbackId, questionId) => {
    if (feedbackId && questionId) {
      if (window.confirm("Are you sure want to delete?")) {
        dispatch(
          feedbackSettingActions.deleteFeedbackQuestion(
            feedbackId,
            questionId,
            cookies.accessToken
          )
        );
      }
    }
  };

  const tableColumns = [
    {
      dataField: "question",
      text: "Question",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="action-buttons">
            <button
              type="button"
              title="Edit Question"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-6"
              onClick={() => {
                setSelectedQuestion(row);
                dispatch(
                  feedbackSettingActions.toggleFeedbackQuestionPopup(true)
                );
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <Svg
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </button>
            <button
              type="button"
              title="Delete Question"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => deleteQuestion(row.feedback_id, row.id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <Svg
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </button>
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px",
      },
    },
  ];

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if (page !== 0) {
        dispatch(
          feedbackSettingActions.changeCurrentPage(
            page - 1,
            maxFeedbackQuestionPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (
        sortField !== feedbackQuestionSortField ||
        sortOrder !== feedbackQuestionSortOrder
      ) {
        dispatch(
          feedbackSettingActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  return (
    <>
      <Card>
        <CardHeader title={"Feedback Settings"}>
          {feedback?.expires_at && (
            <div style={{ display: "flex", height: 70, alignItems: "center" }}>
              <small>
                Next Scheduled: <b>{feedback?.expires_at}</b>
              </small>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Formik
            enableReinitialize={true}
            validationSchema={FeedbackSchema}
            initialValues={
              feedback
                ? { ...feedback }
                : {
                    title: "",
                    completion_message: "",
                    schedule_type_org: "monthly",
                    expiry_in_days: "",
                    status_org: 1,
                  }
            }
            onSubmit={(values) => {
              console.log(values);
              saveFeedbackData(values);
            }}
          >
            {({ handleSubmit, values, handleChange, errors }) => {
              return (
                <>
                  {feedbackLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form
                    className="form form-label-right"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group row">
                      <div className="col-xs-12 col-md-6">
                        <Field
                          type="text"
                          value={values?.title}
                          name="title"
                          component={Input}
                          placeholder="Feedback Name"
                          label="Feedback Name"
                          onChange={handleChange}
                          disabled={feedbackLoading}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <Field
                          type="text"
                          value={values?.completion_message}
                          name="completion_message"
                          component={Input}
                          placeholder="Feedback Completion Message"
                          label="Feedback Completion Message"
                          onChange={handleChange}
                          disabled={feedbackLoading}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-md-6">
                        <Field
                          type="number"
                          value={values?.expiry_in_days}
                          name="expiry_in_days"
                          component={Input}
                          placeholder="Feedback Expire in Days"
                          label="Feedback Expire in Days"
                          onChange={handleChange}
                          disabled={feedbackLoading}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <Select
                          id="schedule_type"
                          onChange={handleChange}
                          value={values?.schedule_type_org}
                          name="schedule_type_org"
                          label="Feedback Schedule Type"
                          disabled={feedbackLoading}
                        >
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          {/* <option value="half-yearly">Half Yearly</option>
                          <option value="yearly">Yearly</option> */}
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-md-6">
                        <Select
                          id="status"
                          onChange={handleChange}
                          value={values?.status_org}
                          name="status_org"
                          label="Status"
                          disabled={feedbackLoading}
                        >
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </Select>
                      </div>
                      <div className="col-xs-2 col-lg-2">
                        <label htmlFor="feedbackSubmit">{""} &nbsp;</label>
                        <br />
                        <button
                          type="submit"
                          id="feedbackSubmit"
                          className="btn btn-primary btn-elevate"
                          disabled={feedbackLoading}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="separator separator-dashed my-5" />
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12 col-md-12 text-right">
                        <button
                          type="button"
                          className="btn btn-primary btn-elevate"
                          onClick={() =>
                            dispatch(
                              feedbackSettingActions.toggleFeedbackQuestionPopup(
                                true
                              )
                            )
                          }
                        >
                          Add Question
                        </button>
                      </div>
                    </div>
                    <div className="form-group feedback-question-table">
                      <Table
                        listLoading={feedbackQuestionLoading}
                        entities={feedbackQuestions || []}
                        columns={tableColumns}
                        paginationOptions={paginationOptions}
                        totalCount={totalFeedbackQuestionRecords}
                        handleTableChange={(type, newState) =>
                          handleTableChange(type, newState)
                        }
                        sortState={{
                          dataField: feedbackQuestionSortField,
                          order: feedbackQuestionSortOrder,
                        }}
                      />
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
      <FeedbackQuestionDialog
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
      />
    </>
  );
};

export default memo(FeedbackSettings);
