import { put, takeLatest, call, all, select } from "redux-saga/effects";
import ShortUniqueId from "short-unique-id";
import queryString from "query-string";

import {
  deleteApiToken,
  getApiToken,
  postApiToken,
  putApiToken,
} from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  GET_FEEDBACK_DATA: "GET_FEEDBACK_DATA",
  SET_FEEDBACK_DATA: "SET_FEEDBACK_DATA",
  GET_FEEDBACK_DATA_LOADING: "GET_FEEDBACK_DATA_LOADING",
  UPDATE_FEEDBACK_DATA: "UPDATE_FEEDBACK_DATA",
  SET_FEEDBACK_QUESTION_LOADING: "SET_FEEDBACK_QUESTION_LOADING",
  GET_FEEDBACK_QUESTION: "GET_FEEDBACK_QUESTION",
  SET_FEEDBACK_QUESTION: "SET_FEEDBACK_QUESTION",
  CREATE_FEEDBACK_QUESTION: "CREATE_FEEDBACK_QUESTION",
  UPDATE_FEEDBACK_QUESTION: "UPDATE_FEEDBACK_QUESTION",
  CLEAR_FEEDBACK_QUESTION: "CLEAR_FEEDBACK_QUESTION",
  SET_FEEDBACK_QUESTION_CURRENT_PAGE: "SET_FEEDBACK_QUESTION_CURRENT_PAGE",
  CHANGE_FEEDBACK_QUESTION_CURRENT_PAGE:
    "CHANGE_FEEDBACK_QUESTION_CURRENT_PAGE",
  CHANGE_FEEDBACK_QUESTION_SORT: "CHANGE_FEEDBACK_QUESTION_SORT",
  TOGGLE_FEEDBACK_QUESTION_POPUP: "TOGGLE_FEEDBACK_QUESTION_POPUP",
  SET_FEEDBACK_QUESTION_FORM_LOADING: "SET_FEEDBACK_QUESTION_FORM_LOADING",
  GET_FEEDBACK_QUESTION_FORM_DATA: "GET_FEEDBACK_QUESTION_FORM_DATA",
  SET_FEEDBACK_QUESTION_FORM_DATA: "SET_FEEDBACK_QUESTION_FORM_DATA",
  DELETE_FEEDBACK_QUESTION: "DELETE_FEEDBACK_QUESTION",
  SET_FEEDBACK_REPORT_LOADING: "SET_FEEDBACK_REPORT_LOADING",
  GET_FEEDBACK_REPORT_FORM_DATA: "GET_FEEDBACK_REPORT_FORM_DATA",
  SET_FEEDBACK_REPORT_FORM_DATA: "SET_FEEDBACK_REPORT_FORM_DATA",
  GET_FEEDBACK_REPORT: "GET_FEEDBACK_REPORT",
  SET_FEEDBACK_REPORT: "SET_FEEDBACK_REPORT",
  GET_FEEDBACK_REOPEN: "GET_FEEDBACK_REOPEN",
  SET_FEEDBACK_REOPEN: "SET_FEEDBACK_REOPEN",
  UPDATE_FEEDBACK_REOPEN: "UPDATE_FEEDBACK_REOPEN",
};

const initialFeedbackLinkState = {
  feedback: {},
  feedbackLoading: false,
  nextFeedbackScheduled: "",
  feedbackQuestions: [],
  totalFeedbackQuestionRecords: 0,
  feedbackQuestionLoading: false,
  currentQuestionPage: 0,
  maxFeedbackQuestionPerPage: 100,
  feedbackQuestionSortField: "id",
  feedbackQuestionSortOrder: "desc",
  showQuestionPopup: false,
  feedbackQuestionFormLoading: false,
  feedbackQuestionFormData: {},
  feedbackReportLoading: false,
  feedbackReportFormData: {},
  feedbackReport: {},
  feedbackReopen: false,
};

export const reducer = (state = initialFeedbackLinkState, action) => {
  switch (action.type) {
    case actionTypes.SET_FEEDBACK_DATA: {
      const { feedback } = action.payload;
      return {
        ...state,
        feedback,
      };
    }
    case actionTypes.GET_FEEDBACK_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackLinkLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_QUESTION_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }
    case actionTypes.CHANGE_FEEDBACK_QUESTION_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }
    case actionTypes.SET_FEEDBACK_QUESTION_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackListLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_QUESTION: {
      const { feedbackQuestion } = action.payload;
      const feedbackQuestions = feedbackQuestion?.["feedback-questions"]?.length
        ? feedbackQuestion["feedback-questions"]
        : [];
      const totalFeedbackQuestionRecords =
        state.isFilter && feedbackQuestion?.recordsTotal
          ? feedbackQuestion?.recordsFiltered
          : feedbackQuestion?.recordsTotal ||
            feedbackQuestion?.["feedback-questions"]?.length ||
            0;

      return {
        ...state,
        totalFeedbackQuestionRecords,
        feedbackQuestions,
      };
    }
    case actionTypes.CHANGE_FEEDBACK_QUESTION_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        feedbackQuestionSortField: field,
        feedbackQuestionSortOrder: order,
      };
    }
    case actionTypes.CLEAR_FEEDBACK_QUESTION: {
      return {
        ...state,
        totalFeedbackRecords: undefined,
        feedbackList: [],
      };
    }
    case actionTypes.OPEN_FEEDBACK_MORE_DETAIL_POPUP: {
      const { flag } = action.payload;
      return {
        ...state,
        moreDetailPopup: flag,
      };
    }
    case actionTypes.TOGGLE_FEEDBACK_QUESTION_POPUP: {
      const { flag } = action.payload;
      return {
        ...state,
        showQuestionPopup: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_QUESTION_FORM_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackQuestionFormLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_QUESTION_FORM_DATA: {
      const { feedbackQuestionFormData } = action.payload;
      return {
        ...state,
        feedbackQuestionFormData,
      };
    }
    case actionTypes.SET_FEEDBACK_REPORT_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackReportLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_REPORT_FORM_DATA: {
      const { feedbackReportFormData } = action.payload;
      return {
        ...state,
        feedbackReportFormData,
      };
    }
    case actionTypes.SET_FEEDBACK_REPORT: {
      const { feedbackReport } = action.payload;
      return {
        ...state,
        feedbackReport,
      };
    }
    case actionTypes.SET_FEEDBACK_REOPEN: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackReopen: flag,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  getFeedback: (accessToken) => ({
    type: actionTypes.GET_FEEDBACK_DATA,
    payload: { accessToken },
  }),
  setFeedback: (feedback) => ({
    type: actionTypes.SET_FEEDBACK_DATA,
    payload: { feedback },
  }),
  setFeedbackLoading: (flag) => ({
    type: actionTypes.GET_FEEDBACK_DATA_LOADING,
    payload: { flag },
  }),
  updateFeedbackData: (feedback, accessToken) => ({
    type: actionTypes.UPDATE_FEEDBACK_DATA,
    payload: { feedback, accessToken },
  }),
  setFeedbackQuestionLoading: (flag) => ({
    type: actionTypes.SET_FEEDBACK_QUESTION_LOADING,
    payload: { flag },
  }),
  getFeedbackQuestion: (
    feedbackId,
    sortField,
    sortOrder,
    page,
    length,
    accessToken
  ) => ({
    type: actionTypes.GET_FEEDBACK_QUESTION,
    payload: { feedbackId, page, length, accessToken, sortField, sortOrder },
  }),
  setFeedbackQuestion: (feedbackQuestion) => ({
    type: actionTypes.SET_FEEDBACK_QUESTION,
    payload: { feedbackQuestion },
  }),
  createFeedbackQuestionData: (feedbackId, questionData, accessToken) => ({
    type: actionTypes.CREATE_FEEDBACK_QUESTION,
    payload: { feedbackId, questionData, accessToken },
  }),
  updateFeedbackQuestionData: (
    feedbackId,
    questionId,
    questionData,
    accessToken
  ) => ({
    type: actionTypes.UPDATE_FEEDBACK_QUESTION,
    payload: { feedbackId, questionId, questionData, accessToken },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_FEEDBACK_QUESTION }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_FEEDBACK_QUESTION_CURRENT_PAGE,
    payload: { page, accessToken, length },
  }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_FEEDBACK_QUESTION_SORT,
    payload: { field, order, accessToken },
  }),
  toggleFeedbackQuestionPopup: (flag) => ({
    type: actionTypes.TOGGLE_FEEDBACK_QUESTION_POPUP,
    payload: { flag },
  }),
  setFeedbackQuestionFormLoading: (flag) => ({
    type: actionTypes.SET_FEEDBACK_QUESTION_FORM_LOADING,
    payload: { flag },
  }),
  getFeedbackQuestionFormData: (feedbackId, questionId, accessToken) => ({
    type: actionTypes.GET_FEEDBACK_QUESTION_FORM_DATA,
    payload: { feedbackId, questionId, accessToken },
  }),
  setFeedbackQuestionFormData: (feedbackQuestionFormData) => ({
    type: actionTypes.SET_FEEDBACK_QUESTION_FORM_DATA,
    payload: { feedbackQuestionFormData },
  }),
  deleteFeedbackQuestion: (feedbackId, questionId, accessToken) => ({
    type: actionTypes.DELETE_FEEDBACK_QUESTION,
    payload: { feedbackId, questionId, accessToken },
  }),
  setFeedbackReportLoading: (flag) => ({
    type: actionTypes.SET_FEEDBACK_REPORT_LOADING,
    payload: { flag },
  }),
  getFeedbackReportFormData: (accessToken) => ({
    type: actionTypes.GET_FEEDBACK_REPORT_FORM_DATA,
    payload: { accessToken },
  }),
  setFeedbackReportFormData: (feedbackReportFormData) => ({
    type: actionTypes.SET_FEEDBACK_REPORT_FORM_DATA,
    payload: { feedbackReportFormData },
  }),
  getFeedbackReport: (reportData, accessToken) => ({
    type: actionTypes.GET_FEEDBACK_REPORT,
    payload: { reportData, accessToken },
  }),
  setFeedbackReport: (feedbackReport) => ({
    type: actionTypes.SET_FEEDBACK_REPORT,
    payload: { feedbackReport },
  }),
  checkFeedbackReOpen: (clientId, accessToken) => ({
    type: actionTypes.GET_FEEDBACK_REOPEN,
    payload: { clientId, accessToken },
  }),
  setFeedbackReOpen: (flag) => ({
    type: actionTypes.SET_FEEDBACK_REOPEN,
    payload: { flag },
  }),
  updateFeedbackReopen: (clientId, accessToken, isMainUser = true) => ({
    type: actionTypes.UPDATE_FEEDBACK_REOPEN,
    payload: { clientId, accessToken, isMainUser },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GET_FEEDBACK_DATA, getFeedbackSaga);
  yield takeLatest(actionTypes.UPDATE_FEEDBACK_DATA, updateFeedbackSaga);
  yield takeLatest(
    actionTypes.CHANGE_FEEDBACK_QUESTION_CURRENT_PAGE,
    function* changePageSaga(action) {
      const {
        feedback,
        feedbackQuestionSortField,
        feedbackQuestionSortOrder,
      } = yield select((state) => state.feedbackSettings);
      const { page, length, accessToken } = action.payload;
      yield put(
        actions.getFeedbackQuestion(
          feedback?.id,
          feedbackQuestionSortField,
          feedbackQuestionSortOrder,
          page,
          length,
          accessToken
        )
      );
    }
  );

  yield takeLatest(
    actionTypes.CHANGE_FEEDBACK_QUESTION_SORT,
    function* changeSortSaga(action) {
      let { field, order, accessToken } = action.payload;
      let { feedback, currentPage, maxFeedbackPerPage } = yield select(
        (state) => state.feedbackSettings
      );
      yield put(
        actions.getFeedbackQuestion(
          feedback?.id,
          field,
          order,
          currentPage,
          maxFeedbackPerPage,
          accessToken
        )
      );
    }
  );
  yield takeLatest(actionTypes.GET_FEEDBACK_QUESTION, getFeedbackQuestionSaga);
  yield takeLatest(
    actionTypes.CREATE_FEEDBACK_QUESTION,
    createFeedbackQuestionSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_FEEDBACK_QUESTION,
    updateFeedbackQuestionSaga
  );
  yield takeLatest(
    actionTypes.GET_FEEDBACK_QUESTION_FORM_DATA,
    getFeedbackQuestionFormSaga
  );
  yield takeLatest(
    actionTypes.DELETE_FEEDBACK_QUESTION,
    deleteFeedbackQuestionSaga
  );
  yield takeLatest(
    actionTypes.GET_FEEDBACK_REPORT_FORM_DATA,
    getFeedbackReportFormDataSaga
  );
  yield takeLatest(actionTypes.GET_FEEDBACK_REPORT, getFeedbackReportSaga);
  yield takeLatest(actionTypes.GET_FEEDBACK_REOPEN, checkFeedbackReopenSaga);
  yield takeLatest(
    actionTypes.UPDATE_FEEDBACK_REOPEN,
    updateFeedbackReopenSaga
  );
}

function* getFeedbackSaga(action) {
  let { accessToken } = action.payload;
  yield all([put(actions.setFeedbackLoading(true))]);

  try {
    const response = yield call(
      getApiToken,
      apiUrl("FEEDBACK_DATA"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        let feedback = responseData.data["feedback"];
        yield all([
          put(actions.setFeedback(feedback)),
          put(actions.setFeedbackLoading(false)),
        ]);
      } else {
        yield all([put(actions.setFeedbackLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Feedback",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackLoading(false))]);
      yield put(
        generalActions.addToast(
          "Get Feedback",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackLoading(false))]);
    yield put(
      generalActions.addToast(
        "Get Feedback",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateFeedbackSaga(action) {
  let { feedback, accessToken } = action.payload;
  yield all([put(actions.setFeedbackLoading(true))]);

  let formData = {
    title: feedback?.title,
    completion_message: feedback?.completion_message,
    schedule_type: feedback?.schedule_type_org,
    expiry_in_days: feedback?.expiry_in_days,
    status: feedback?.status_org,
  };

  try {
    const response = yield call(
      postApiToken,
      apiUrl("FEEDBACK_DATA"),
      accessToken,
      queryString.stringify(formData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);

      if (responseData?.data) {
        yield all([
          put(actions.getFeedback(accessToken)),
          put(actions.setFeedbackLoading(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Update Feedback",
            "Feedback data updated successfully",
            "success",
            uid()
          )
        );
      } else {
        yield all([put(actions.setFeedbackLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Feedback",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackLoading(false))]);
      yield put(
        generalActions.addToast(
          "Update Feedback",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackLoading(false))]);
    yield put(
      generalActions.addToast(
        "Update Feedback",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getFeedbackQuestionSaga(action) {
  let {
    feedbackId,
    page,
    length,
    accessToken,
    sortField,
    sortOrder,
  } = action.payload;
  yield put(actions.setFeedbackQuestionLoading(true));
  const filterParams = {
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]= &columns[0][name]= &columns[0][searchable]= false&columns[0][orderable]= false&columns[0][search][value]= &columns[0][search][regex]= false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("FEEDBACK_DATA")}/${feedbackId}/questions`,
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        yield all([
          put(actions.setFeedbackQuestion(responseData.data)),
          put(actions.setFeedbackQuestionLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setFeedbackQuestionLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setFeedbackQuestionLoading(false));
        yield put(
          generalActions.addToast(
            "Feedback Question",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setFeedbackQuestionLoading(false));
      yield put(
        generalActions.addToast(
          "Feedback Question",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    yield put(actions.setFeedbackQuestionLoading(false));
    yield put(
      generalActions.addToast(
        "Feedback Question",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getFeedbackQuestionFormSaga(action) {
  let { feedbackId, questionId, accessToken } = action.payload;
  yield all([put(actions.setFeedbackQuestionFormLoading(true))]);

  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("FEEDBACK_DATA")}/${feedbackId}/questions/${
        questionId ? questionId + "/edit" : "create"
      }`,
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        yield all([
          put(actions.setFeedbackQuestionFormData(responseData.data)),
          put(actions.setFeedbackQuestionFormLoading(false)),
        ]);
      } else {
        yield all([put(actions.setFeedbackQuestionFormLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackQuestionFormLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Feedback Question Form",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackQuestionFormLoading(false))]);
      yield put(
        generalActions.addToast(
          "Get Feedback Question Form",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackQuestionFormLoading(false))]);
    yield put(
      generalActions.addToast(
        "Get Feedback Question Form",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* createFeedbackQuestionSaga(action) {
  let { feedbackId, questionData, accessToken } = action.payload;
  yield all([put(actions.setFeedbackQuestionLoading(true))]);

  try {
    const response = yield call(
      postApiToken,
      `${apiUrl("FEEDBACK_DATA")}/${feedbackId}/questions`,
      accessToken,
      queryString.stringify(questionData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);

      if (responseData?.data) {
        yield all([
          put(actions.getFeedback(accessToken)),
          put(actions.setFeedbackQuestionLoading(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Create Feedback Question",
            "Feedback question created successfully",
            "success",
            uid()
          )
        );
      } else {
        yield all([put(actions.setFeedbackQuestionLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackQuestionLoading(false))]);
        yield put(
          generalActions.addToast(
            "Create Feedback Question",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackQuestionLoading(false))]);
      yield put(
        generalActions.addToast(
          "Create Feedback Question",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackQuestionLoading(false))]);
    yield put(
      generalActions.addToast(
        "Create Feedback Question",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateFeedbackQuestionSaga(action) {
  let { feedbackId, questionId, questionData, accessToken } = action.payload;
  yield all([put(actions.setFeedbackQuestionLoading(true))]);

  try {
    const response = yield call(
      putApiToken,
      `${apiUrl("FEEDBACK_DATA")}/${feedbackId}/questions/${questionId}`,
      queryString.stringify(questionData),
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);

      if (responseData?.data) {
        yield all([
          put(actions.getFeedback(accessToken)),
          put(actions.setFeedbackQuestionLoading(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Update Feedback Question",
            "Feedback question updated successfully",
            "success",
            uid()
          )
        );
      } else {
        yield all([put(actions.setFeedbackQuestionLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackQuestionLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Feedback Question",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackQuestionLoading(false))]);
      yield put(
        generalActions.addToast(
          "Update Feedback Question",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackQuestionLoading(false))]);
    yield put(
      generalActions.addToast(
        "Update Feedback Question",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* deleteFeedbackQuestionSaga(action) {
  let { feedbackId, questionId, accessToken } = action.payload;
  yield all([put(actions.setFeedbackQuestionLoading(true))]);

  try {
    const response = yield call(
      deleteApiToken,
      `${apiUrl("FEEDBACK_DATA")}/${feedbackId}/questions/${questionId}`,
      accessToken,
      null
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);

      if (responseData?.data) {
        yield all([
          put(actions.getFeedback(accessToken)),
          put(actions.setFeedbackQuestionLoading(false)),
        ]);

        yield put(
          generalActions.addToast(
            "Delete Feedback Question",
            "Feedback question deleted successfully",
            "success",
            uid()
          )
        );
      } else {
        yield all([put(actions.setFeedbackQuestionLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackQuestionLoading(false))]);
        yield put(
          generalActions.addToast(
            "Delete Feedback Question",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackQuestionLoading(false))]);
      yield put(
        generalActions.addToast(
          "Delete Feedback Question",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackQuestionLoading(false))]);
    yield put(
      generalActions.addToast(
        "Delete Feedback Question",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getFeedbackReportFormDataSaga(action) {
  let { accessToken } = action.payload;
  yield all([put(actions.setFeedbackReportLoading(true))]);

  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("FEEDBACK_REPORT_DATA")}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        yield all([
          put(actions.setFeedbackReportFormData(responseData.data)),
          put(actions.setFeedbackReportLoading(false)),
        ]);
      } else {
        yield all([put(actions.setFeedbackReportLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackReportLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Feedback Report Form",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setFeedbackReportLoading(false))]);
      yield put(
        generalActions.addToast(
          "Get Feedback Report Form",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackReportLoading(false))]);
    yield put(
      generalActions.addToast(
        "Get Feedback Report Form",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getFeedbackReportSaga(action) {
  let { reportData, accessToken } = action.payload;
  yield all([put(actions.setFeedbackReportLoading(true))]);

  const formData = {
    feedback_period: reportData?.feedbackPeriod,
    report_type: reportData?.reportType,
    client_type: reportData?.clientType,
  };

  try {
    const response = yield call(
      postApiToken,
      `${apiUrl("FEEDBACK_REPORT_DATA")}`,
      accessToken,
      queryString.stringify(formData)
    );
    if (response.status === 200) {
      if (reportData?.reportType === "chart") {
        let responseData = yield call([response, response.json]);
        if (responseData?.data) {
          yield all([
            put(actions.setFeedbackReport(responseData.data)),
            put(actions.setFeedbackReportLoading(false)),
          ]);
        } else {
          yield all([put(actions.setFeedbackReportLoading(false))]);
        }
      } else if (reportData?.reportType === "export") {
        let responseData = yield call([response, response.text]);
        const link = document.createElement("a");
        link.href = responseData;
        link.setAttribute("download", "FeedbackReport.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        yield all([put(actions.setFeedbackReportLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setFeedbackReportLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Feedback Report",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([put(actions.setFeedbackReportLoading(false))]);
      yield put(
        generalActions.addToast(
          "Get Feedback Report",
          errorData?.message || "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setFeedbackReportLoading(false))]);
    yield put(
      generalActions.addToast(
        "Get Feedback Report",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* checkFeedbackReopenSaga(action) {
  let { clientId, accessToken } = action.payload;
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("FEEDBACK_REOPEN")}/${clientId}/reopen`,
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        yield all([put(actions.setFeedbackReOpen(true))]);
      } else {
        yield put(actions.setFeedbackReOpen(false));
      }
    } else {
      yield put(actions.setFeedbackReOpen(false));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setFeedbackReOpen(false));
    yield put(
      generalActions.addToast(
        "Get Feedback Question Form",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateFeedbackReopenSaga(action) {
  let { clientId, accessToken, isMainUser } = action.payload;
  try {
    const response = yield call(
      postApiToken,
      `${apiUrl("FEEDBACK_REOPEN")}/${clientId}/reopen`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        if (isMainUser) {
          yield put(actions.setFeedbackReOpen(false));
        }
        yield all([
          put(
            generalActions.addToast(
              "Feedback Reopen",
              "You have reopened feedback!",
              "success",
              uid()
            )
          ),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(
          generalActions.addToast(
            "Feedback Reopen",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      if (errorData.message) {
        yield put(
          generalActions.addToast(
            "Feedback Reopen",
            errorData.message,
            "error",
            uid()
          )
        );
      } else {
        yield put(
          generalActions.addToast(
            "Feedback Reopen",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      generalActions.addToast(
        "Feedback Reopen",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
